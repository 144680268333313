export const releaseInfo =
 {
 	branch: "HEAD",
 	lastCommit: "09/11/2024 18:24:27",
 	lastTag: "",
 	buildType: "beta",
 	company: "Pinnacle Power Services",
 	user: "root",
 	compileTime: "09/11/2024 18:30:47"
 }
