import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as hopscotch from 'hopscotch';
import { filter } from 'rxjs/operators';

import { RoutePartsService } from "./services/route-parts/route-parts.service";
import { AuthService } from './services/auth/auth.service';
import { DataStoreService } from './services/datastore.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  appTitle = 'Pinnacle Power';
  pageTitle = '';

  constructor(public title: Title,
    private router: Router,
    private activeRoute: ActivatedRoute,
    //public auth: AuthService,
     private routePartsService: RoutePartsService,
     public snackBar: MatSnackBar) {

    /*
    Now using route guards
    this.auth.handleAuthentication();
    */
  }

  ngOnInit() {
    this.changePageTitle();
  }
  /*
  ***** Tour Steps ****
  * You can supply tourSteps directly in hopscotch.startTour instead of 
  * returning value by invoking tourSteps method,
  * but DOM query methods(querySelector, getElementsByTagName etc) will not work
  */
  tourSteps(): any {
     
  }
  changePageTitle() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((routeChange) => {
      var routeParts = this.routePartsService.generateRouteParts(this.activeRoute.snapshot);
      if (!routeParts.length)
        return this.title.setTitle(this.appTitle);
      // Extract title from parts;
      this.pageTitle = routeParts
        .reverse()
        .map((part) => part.title)
        .reduce((partA, partI) => { return `${partA} > ${partI}` });
      this.pageTitle += ` | ${this.appTitle}`;
      this.title.setTitle(this.pageTitle);

    });
 
  }
}
