import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IdbService } from './idb.service';
import { Command } from 'app/models/command';
import { ToolboxService } from 'app/toolbox.service';
import { SiteReviewService } from './site-review.service';
import { map } from 'rxjs/operators';
import { RowComp } from 'ag-grid-community';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class BulkLoaderService {

  constructor(private idb: IdbService, private tb: ToolboxService, private db: SiteReviewService) { }

  jobListTrigger = new BehaviorSubject(0)
  percentage = new BehaviorSubject(0);
  private _packageCount;
  private _countDown;
  private _percentage: number = 0;
  existingIds: Set<number> = new Set([]); 
  loadedJobRecords = [];

  
  // comment this function to avoid confusion, this is the old bulkLoader

  // async bulkLoader_old(gridApi) {
  //   let commands = [];
  //   await this.idb.clearData('jobs')
  //   if (gridApi) gridApi.setRowData([]);
  //   for (var y = 2019; y <= new Date().getFullYear(); y++) {
  //     for (var m = 0; m <= 11; m++) {
  //       var d = new Date(y, m, 1);
  //       if (d <= new Date()) {
  //         console.log('date', d)
  //         var endDate;
  //         if (m == 11) {
  //           endDate = new Date(y + 1, 0, 1);
  //         } else {
  //           endDate = new Date(y, m + 1, 1);
  //         }
  //         endDate.setSeconds(endDate.getSeconds() - 1);
  //         commands.push({ startDate: d, endDate: endDate })
  //       }
  //     }
  //   }
  //   this._packageCount = commands.length;
  //   this._countDown = commands.length;
  //   commands = commands.reverse();
  //   commands.forEach(cmd => {
  //     this.getChunk(cmd.startDate, cmd.endDate, gridApi)
  //   });
  // }


  async bulkLoader(gridApi) {
    let commands = [];
    await this.idb.clearData('jobs');
    this.existingIds = new Set([]);

    gridApi.setRowData([]);

    for (let y = 2019; y <= new Date().getFullYear(); y++) {
      if (y > 2022) {
        // Generate weekly intervals for the current year
        let currentDate = new Date(y, 0, 1);
        while (currentDate <= new Date()) {
          const startDate = new Date(currentDate);
          let endDate = new Date(currentDate);
          endDate.setDate(endDate.getDate() + 7);
          if (endDate > new Date()) {
            endDate.setTime(new Date().getTime()); // Set to current date
          }
          endDate = this.setToEndOfDay(endDate);
          commands.push({ startDate, endDate });
          currentDate.setDate(currentDate.getDate() + 7);
        }
      } else {
        // Generate monthly intervals for previous years
        for (let m = 0; m <= 11; m++) {
          const d = new Date(y, m, 1);
          if (d <= new Date()) {
            console.log('date', d);
            let endDate;
            if (m === 11) {
              endDate = new Date(y + 1, 0, 1);
            } else {
              endDate = new Date(y, m + 1, 1);
            }
            // Set start date to 12:00 AM (midnight)
            const startDate = new Date(d);
            startDate.setHours(0, 0, 0, 0);
            // Set end date to the last minute of the month
            endDate.setHours(23, 59, 59, 999);
            commands.push({ startDate, endDate });
          }
        }
      }
    }

    // Now your `commands` array contains precise datetime intervals
    console.log(commands);
    this._packageCount = commands.length;
    this._countDown = commands.length;
    commands = commands.reverse();
    commands.forEach(cmd => {
      return this.getChunk(cmd.startDate, cmd.endDate, gridApi);
    });
  }


  setToEndOfDay(dateTimeString: Date): Date | null {
    try {
      // Parse the input date string
      const parsedDate = new Date(dateTimeString);

      // Check if parsing was successful
      if (isNaN(parsedDate.getTime())) {
        console.error('Invalid date format. Please provide a valid date and time.');
        return null;
      }

      // Set the time to 11:59 PM
      parsedDate.setHours(23, 59, 0, 0);
      return parsedDate;
    } catch (error) {
      console.error('Error occurred while processing the date:', error);
      return null;
    }
  }

  private callback(e) {
    console.log('callback reload', e)
  }

  private getChunk(b, e, gridApi) {
    this.db.getChunk(b, e)
      .pipe(
        map((retval: any) => {
          retval.forEach(row => {
            try {
              row.updatedDate = new Date(row.updatedDate)

            } catch {
              console.warn('ERROR CONVERTING UPDATED DATE', row)
            }
          });
          return retval;
        })
      )
      .subscribe({
        next: async (rows: any) => {
          this._countDown = this._countDown - 1;

          this._percentage = ((this._packageCount - this._countDown) / this._packageCount) * 100
          this.percentage.next(this._percentage);
          console.log(`countdown: ${this._countDown}`);
          if (rows.length > 0) {
            //make sure to eliminate duplciates
            let filteredRows = [];
            filteredRows = rows.filter((newRow: any) => !this.existingIds.has(newRow.jobID));
            filteredRows.forEach((row: any) => this.existingIds.add(row.jobID));
            // filteredRows.forEach((row: any) => {
            //   let date = new Date(row.updatedDate);
            //   if (isNaN(date.getTime())) {
            //     // If date is invalid, set to current date and time
            //     row.updatedDate = new Date();
            //   }

            //   row.updatedDate = new Date(row.updatedDate);
            // })

            if (filteredRows.length > 0) {
              if (this._countDown > -1) {
                filteredRows = await this.clearRows(gridApi, filteredRows);
                await gridApi.applyTransactionAsync({ add: filteredRows }, this.callback);
                //then we update the local DB
                this.idb.insertBulk('jobs', filteredRows, async () => {
                  console.log('done inserting ' + filteredRows.length + ' rows');
                });
              } else {
                console.warn("For some reason we keep loading " + this._countDown)
              }
            }

          }
          if (this._countDown === 0) {
            /* THIS IS A HACK.  
            I HAVE NO IDEA WHY, BUT WE STILL GET DUPLICATES IN AGGRID, SO I RESET THE ENTIRE THING WHEN WE ARE DONE 
            AND THEN JUST RUN THE UPDATE LOADER TO SEE IF WE MISSED ANYTHING WHILE DOING THE UPDATE
            */

            // let jobs = await this.idb.list("jobs");
            // gridApi.setRowData(jobs);
           
            this.updateLoader(gridApi);
          }
        }
      })
  }

  async clearRows(gridApi, rows) {
    var filteredRecs = [];

    rows.forEach(row => {
      const node = gridApi.getRowNode(row.jobID);
      if (!node) {

        filteredRecs.push(row);
      } else {
        console.log("DUPLICATE FOUND", row)
      }
    });
    return filteredRecs;
  }


  async updateLoader(gridApi) {

    let ed: Date = new Date("1/1/2019");
    var dbLastModDate = await this.idb.updateStatus('first')
    if (dbLastModDate.length > 0) {
      ed = new Date(dbLastModDate[0]);
    } else {
      // this.bulkLoader(gridApi);
      return;
    }

    let sqlExpireDate: string = ed.toLocaleDateString() + ' ' + ed.toLocaleTimeString();

    console.log('retrieving updated data starting ' + sqlExpireDate)

    let cmd = new Command();
    cmd.procedure = "cmdJobsMasterUpdateLimited";
    cmd.addParameter("ExpireDate", sqlExpireDate);
    this.db.command(cmd)
      .subscribe({
        next: async retval => {

          let toUpdate: Array<any> = [];
          if (gridApi) {

            //cycle through the result set and update rows that match.
            gridApi.forEachNode((node) => {
              try {
                if (node.data && retval) {
                  //if there is no data at all, then this will give an error.
                  let check = retval.filter(r => r.jobID == node.data.jobID)
                  if (check.length > 0) {
                    node.updateData(check[0]);  //update the grid
                    toUpdate.push(check[0]);    //add to update array for indexeddb
                  }
                  //remove the updated record from the result set
                  retval = retval.filter(r => r.jobID != node.data.jobID)
                }
              } catch (exception) {
                console.warn('Error filtering out old records from node.data', node.data, exception);
              }
            });

            //anything left gets added to the top
            gridApi.applyTransaction({ add: retval, addIndex: 0 });
          }
          //then we update the local DB
          await this.idb.insertBulk('jobs', retval, function () { console.log('done inserting') });
          await this.idb.updateBulk('jobs', toUpdate, function () { console.log('done updating') });

        }
      });
  }

}
