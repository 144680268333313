import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
//import * as auth0 from 'auth0-js';
import { SiteReviewService } from '../site-review.service';
import { environment } from 'environments/environment';

import { HttpClient } from '@angular/common/http';
import { DataStoreService } from '../datastore.service';
import { BehaviorSubject, forkJoin, interval } from 'rxjs';
import { IdbService } from '../idb.service';
const pinncleServer = environment.serverURL;

var hostname = window.location.hostname;
var port = window.location.port;

@Injectable()
export class AuthService implements CanActivate {

  public authToken;
  public sessionId;
  public currentUser: any;
  public appEntryURL: string;
  public redictUrl;
  public signInURL;
  public database: string = "";
  idleInterval: any;


  private isAuthenticated = false; // Set this value dynamically

  private accessTokenKey = 'sr_access_token';
  private idTokenKey = 'sr_id_token';
  public expiresAtKey = 'sr_expires_at';
  private sessionIdKey = 'sr_session_id';
  private profileKey = 'sr_profile';
  // private sessionIdleTime = 60000;

  public logoUrl = new BehaviorSubject("assets/images/logo3.png");

  private webAuthConfig: any = {
    clientID: 'hYbChvXUsqiV64GfwwspR4NvRuOSWHvH',
    // clientID: '65uKZfB-CLM_boxpO2XUSz21ikMFjzcR',
    domain: 'sitereview.auth0.com',
    responseType: 'token id_token',
    //redirectUri: this.redictUrl,
    scope: 'openid profile email'
  }

  //https://console.cloud.google.com/apis/credentials/consent?project=dash-216521
  //https://console.developers.google.com/apis/credentials/oauthclient/966702354234-po6gblj1p2vnbpft7iesc2emmr2dnlf2.apps.googleusercontent.com?project=${your_project_number}


  //I have no idea where this came from.  I finally set up an entire new credential set.
  //public googleClientID_wtf: string = '966702354234-po6gblj1p2vnbpft7iesc2emmr2dnlf2.apps.googleusercontent.com';

  public googleClientID: string = '344908434643-o06i6t2l6e3gmlvvk5bfmaqced9dkuli.apps.googleusercontent.com';

  /*
  new  creds
  B_VyDDsnXKvaRHXu7fUZVm3E.
  344908434643-o06i6t2l6e3gmlvvk5bfmaqced9dkuli.apps.googleusercontent.com
  */




  public session
  public serverStatus;
  constructor(
    private router: Router,
    private siteReviewService: SiteReviewService,
    private dataStore: DataStoreService,
    private http: HttpClient,
    private idb: IdbService
  ) {



    if (!this.currentUser) {

      this.currentUser = JSON.parse(localStorage.getItem('curUserData'));
      console.log('currentUser', this.currentUser)
    }

    if (!this.session) {
      const session = localStorage.getItem('session');
      if (session != null) {
        this.session = JSON.parse(session);
        if (!this.session.internalContactsID)
          this.session.internalContactsID = 0;

      } else {
        this.session = {};
        if (!this.session.internalContactsID)
          this.session.internalContactsID = 0;
      }

    }


    if (hostname == 'localhost') {

      this.redictUrl = `http://${hostname}:${port}/sessions/loading`;
      this.signInURL = `http://${hostname}:${port}/sessions/signin`;

    } else {

      this.redictUrl = 'https://' + hostname + '/sessions/loading';
      this.signInURL = 'https://' + hostname + '/sessions/signin';

    }


    this.checkUrl();

  }



  checkUrl() {
    let url = document.location.hostname;
    console.log("checkUrl => ", url, url.indexOf('beta'));
    if (url.indexOf('beta') >= 0) {
      this.logoUrl.next("assets/images/dash_beta1.png");
    }
    if (url.indexOf('dash') >= 0) {
      this.logoUrl.next("assets/images/logo3.png");
    }
    if (url.indexOf('dev') >= 0) {
      this.logoUrl.next("assets/images/logo_dev.png");
    }
    if (url.indexOf('local') >= 0) {
      this.logoUrl.next("assets/images/logo_dev.png");
    }
  }
  public login(): void {
    //this.auth0.authorize();
  }


  public async directFromGoogle() {
    var fragmentString = location.hash.substring(1);
    if (fragmentString === '') this.router.navigateByUrl('/sessions/signin');
    // Parse query string to see if page request is coming from OAuth 2.0 server.
    var params: any = {};
    var regex = /([^&=]+)=([^&]*)/g, m;
    while (m = regex.exec(fragmentString)) {
      params[decodeURIComponent(m[1])] = decodeURIComponent(m[2]);
    }
    if (Object.keys(params).length > 0) {
      localStorage.setItem('expires_at', params.expires_in)
      localStorage.setItem('oauth2', JSON.stringify(params));
      if (params['state'] && params['state'] == 'dash_login') {
        const response = await fetch(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${params.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${params.access_token}`
            }
          }
        )
        let json = await response.json()
        let fullData = { access: params, identity: json }
        return fullData
      }
    }

  }




  public async handleAuthentication_new() {
    console.log("Start Authentication")
    if (this.router.url.indexOf('signin') > 0) return false;
    console.log("Begin Login")
    var retval = await this.directFromGoogle()
    console.log("Got Auth Token", retval)
    let gg = retval.access;
    let authResult: any = {};
    authResult.expiresIn = gg.expires_in;
    authResult.access_token = gg.access_token;
    authResult.idToken = gg.id_token;
    authResult.idTokenPayload = { name: retval.identity.name }
    
    //await this.idb.clearData('jobs')

    this.getSessionId(retval.access.id_token)
      .subscribe((res: any) => {
        // redirect if is not a Beta Tester
         let hasPermission =  this.checkUserBetaTester(res.groups);
        //added later:  if the return value is not checked and stopped, this will continue and the redirect
        //will never happen.
        if(!hasPermission) {
          this.siteReviewService.slacker(`Failed attempt to use non-production system by ${res}`);
          return;
        }

        // Set the time that the Access Token will expire at

        //let dashTimeOut = environment.authenticationExpirationTimeoutMinutes * 1000;  //get it in milliseconds
        let dashTimeOut = 60 * 1000;  //get it in milliseconds
        let expiresAt = JSON.stringify(dashTimeOut + new Date().getTime());

        //we will use our own timeout.
        //let googleTimeout = authResult.expiresIn * 1000;
        //let expiresAt = JSON.stringify(googleTimeout + new Date().getTime());

        localStorage.setItem(this.accessTokenKey, authResult.accessToken);
        localStorage.setItem(this.idTokenKey, authResult.idToken);
        localStorage.setItem(this.expiresAtKey, expiresAt);
        localStorage.setItem(this.sessionIdKey, res.sessionId);
        localStorage.setItem('userLogged', 'true');
        localStorage.setItem('lastLogin', new Date().toLocaleDateString());


        this.session.internalContactsID = res.internalContactsID;

        this.siteReviewService.sessionId = res.sessionId;
        localStorage.setItem("session", JSON.stringify(authResult));

        console.log("Session Set.  Loading defaults", new Date());

        this.setCurrentUserData({ 'userLevel': res.userLevel, 'groups': res.groups, 'email': res.email, 'internalContactsID': res.internalContactsID });
        this.siteReviewService.slacker("Successful Login by " + res);


        let obj = this.dataStore.loadAllDefaults()
        forkJoin(obj).subscribe(foremen => {

          console.log("CHECK MINUTES DIFF => ", this.checkMinutesDiff());

          let homeURL = '/reviews/jobs';


          //if the last login was from within 30 minutes, relocated to the last page logged
          if (this.checkMinutesDiff()) {


            window.location.replace(homeURL);
          } else {
            let redir = localStorage.getItem("lastPage")
            console.log('getting last page to redir... => ', redir);
            if (!redir) redir = homeURL;
            if (redir === '/') redir = homeURL;
            if (redir === '/sessions/login') redir = homeURL;
            if (redir.indexOf('login') >= 0) redir = homeURL;
            if (redir.indexOf('signin') >= 0) redir = homeURL;
            if (redir === '/sessions/loading') redir = homeURL;
            if (redir === '/sessions/signin') redir = homeURL;

            window.location.replace(redir);
          }

        });

      }, err => {
        location.href = '/sessions/error';
      })
  }

  checkUserBetaTester(userGroups) {
    let userGroup = 'Beta Tester';
    const foundUser = userGroups.find(item => item.includes(userGroup));

    //If your not on the production server, and aren't a tester, redirect to production
    console.log('testing to see if user is allowed here')
    const host = document.location.hostname;
    if (host.indexOf('dash.pinnaclepowerservices.com') == -1) {
      console.log('user is not on production server');
      if (!foundUser) {
        console.log("Beta Tester user not found, redirecting...");
        alert('Sorry, you are not in the beta tester group. We will redirect you to production Dash');
        let redirUrl = 'https://dash.pinnaclepowerservices.com/sessions/signin'
        window.location.assign(redirUrl);
        return false;
      }else{
        console.log('User is allowed to use Beta products')
       
      }
    
    }
    return true;

    // const betaDomain = document.location.hostname;
    // if (betaDomain.indexOf('beta') >= 0 && foundUser) {
    //   console.log("Found:", foundUser + ' and beta domain... ', betaDomain);
    // } else {
    //   console.log("Beta Tester user not found, redirecting...");
    //   let redirUrl = 'https://dash.pinnaclepowerservices.com/sessions/signin'
    //   window.location.replace(redirUrl);
    //   return;
    // }

  }

  checkMinutesDiff() {
    let lastDateData = localStorage.getItem('lastDate');
    //let lastDate = new Date(lastDateData);
    //let actualDate = new Date().toLocaleDateString();



    let minDiff = (new Date().getTime() - Number(lastDateData)) / 1000;
    minDiff /= 60;
    let minDiffRound = Math.abs(Math.round(minDiff));

    console.log("minutes of difference => ", minDiff)

    if (minDiffRound > 30) {
      return true;
    } else {
      return false;
    }
  }


  private setCurrentUserData(userData: any) {
    localStorage.setItem('curUserData', JSON.stringify(userData));
  }

  private setSession(authResult): void {
    this.session = authResult;
    // Set the time that the Access Token will expire at
    const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
    localStorage.setItem(this.accessTokenKey, authResult.accessToken);
    localStorage.setItem(this.idTokenKey, authResult.idToken);
    localStorage.setItem(this.expiresAtKey, expiresAt);
    localStorage.setItem(this.sessionIdKey, authResult.sessionId);
    this.siteReviewService.sessionId = authResult.sessionId;
    localStorage.setItem("session", JSON.stringify(authResult));
  }

  public logout(): void {

    console.log('login out...')
    // Remove tokens and expiry time from localStorage
    localStorage.removeItem(this.accessTokenKey);
    localStorage.removeItem(this.idTokenKey);
    localStorage.removeItem(this.expiresAtKey);
    localStorage.removeItem(this.sessionIdKey);
    localStorage.removeItem('userLogged');
    localStorage.removeItem("JobMasterActiveTemplateViewID");
    localStorage.removeItem("lastLogin");

    //localStorage.setItem("lastPage", window.location.pathname);
    // var url = `/sessions/signin`;
    // document.location.href = url;
    // console.log(url);


    //Go back to the home route
    //this.router.navigate(['/sessions/signin']);
    //this.router.navigateByUrl('/sessions/signin');
    //changing to doc location so it wipes all javascript variables that may be here.
    document.location.href='/sessions/signin';
  }

  public authenticated(): boolean {
    // Check whether the current time is past the
    // Access Token's expiry time

    let expiresAt = JSON.parse(localStorage.getItem(this.expiresAtKey) || '{}');

    let curUserData = localStorage.getItem('curUserData');
    if (!curUserData)
      expiresAt = {};
    let authenticated = new Date().getTime() < expiresAt;
    console.log('authenticated time => ', authenticated);
    if (authenticated) {
      expiresAt = new Date().getTime() + (1000 * environment.authenticationExpirationTimeoutMinutes)
      //expiresAt = new Date().getTime() + this.sessionIdleTime;
      localStorage.setItem(this.expiresAtKey, expiresAt);

    }
    return authenticated;
  }

  getSessionId(token) {
    return this.http.post(this.siteReviewService.apiServer + '/api/Session?sid=token', { token: token });
  }
  getSession(sID) {
    return this.http.get(this.siteReviewService.apiServer + '/api/session?sid=' + sID);
  }
  getServerStatus(server: string) {
    return this.http.get(server + '/api/server?sid=token');
  }
  getMenu() {

  }

  refreshSessionId(sID) {
    return this.http.put(this.siteReviewService.apiServer + '/api/session/' + sID + '/renew', {});
    //return this.http.post(this.siteReviewService.apiServer + '/api/session/'+sID+'/renew', { sid: sID });
    //return this.http.post(this.siteReviewService.apiServer + '/api/session?sid='+sID+'/renew', {});
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    let browserUrl = window.location.pathname;
    localStorage.setItem('lastDate', new Date().getTime().toString());

    if (browserUrl.indexOf('signin') >= 0 || browserUrl.indexOf('loading') >= 0) {
      return;
    } else {
      localStorage.setItem("lastPage", browserUrl)
    }

    let userLogged = localStorage.getItem('userLogged');
    console.log("CAN ACVTIVATE AUTH (userlogged) => ", userLogged)
    if (userLogged) {
      return true;
    } else {
      console.log('Authentication failure.  redirecting to login, will return to ' + window.location.pathname);
      this.logout();
      //this.router.navigateByUrl('/sessions/signin');
    }
    if (userLogged === null) {
      this.logout();
    }
  }

}