import { Component, ViewChild, ViewContainerRef } from "@angular/core";

import { IToolPanel, IToolPanelParams } from "ag-grid-community";
import { Command } from 'app/models/command';
import { SiteReviewService } from 'app/services/site-review.service';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
export class HistoryComponent implements IToolPanel {




  constructor(private db: SiteReviewService) { }

  results: any[] = [];

  history = {
    column: "",
    table: "",
    keyColumn:"",
    id: 0,
    value: ""
  }


  private params: IToolPanelParams;
 

  agInit(params: IToolPanelParams): void {
    this.params = params;
 

    // calculate stats when new rows loaded, i.e. onModelUpdated
    this.params.api.addEventListener('cellFocused', (rowIndex: number, column: any, rowPinned: string, forceBrowserFocus: boolean) => { this.checkHistory(rowIndex) });


  }

  checkHistory(obj) {
    
    if (obj.column) {
      if(!obj.column.colId) return;
      if(obj.column.colId === 'ag-Grid-AutoColumn') return;

      let rowIndex = obj.rowIndex;

      // use id if there are specific requirements
      console.log(obj.column);
      let column='';
      if(obj.column.colDef.id){
        column = obj.column.colDef.id
      }else{
        column =  obj.column.colDef.columnName;
      }
      console.log("ID",obj.column.colId);
      let table = obj.column.colDef.table;
      //let row = obj.api.getRowNode(rowIndex);
      let row = obj.api.getDisplayedRowAtIndex(rowIndex);
      if(row.group) return ;
      
      let value = row.data[obj.column.colId];
      console.log('field history (value,row, column)',value, row.data, obj.column)
      if (obj.api.getDisplayedRowAtIndex(rowIndex).data) {


        let id = obj.api.getDisplayedRowAtIndex(rowIndex).data.jobID
        //let id = obj.api.getRowNode(rowIndex).data.jobID;

        if(column=='civilGeneralForeman') table='JOB'
        if(column=='civilForeman') table='JOB'
        if(column=='electricForeman') table='JOB'
        if(column=='electricGeneralForeman')  table='JOB'
        if(column=='civilActualEndDate')  table='JOB'
        if(column=='electricActualEndDate')  table='JOB'
        if(column=='bidStatus')  table='JOB'
        if(column)

        if(!table) table='JOB';

        this.history = {
          column: column,
          table: table,
          keyColumn:table+"ID" ,
          id: id,
          value: value
        }
        console.log("seeking history",this.history)
        this.retrieveHistory();
      }



     
      console.log('history', this.history);

      // console.log('getSelectedNodes',this.params.api.getSelectedNodes())
    }
  }

  retrieveHistory() {
    // exec cmdCellHistory 'Job', 'JobID', 6189, 'Address1','AwWdHLKTC5BgBE4lPrbo';
    //@TableName varchar(50), @KeyColumn varchar(50), @KeyValue int =0, @ColumnName varchar(50)
    let obj = this.history;
    let cmd = new Command();
 


    cmd.procedure = "cmdCellHistory"
    cmd.addParameter("TableName", obj.table)
    cmd.addParameter("KeyColumn", obj.keyColumn)
    cmd.addParameter("KeyValue", obj.id)
    cmd.addParameter("ColumnName", obj.column)

    this.db.command(cmd)
      .subscribe(retval => {
        this.results = retval;
        //this.router.navigateByUrl('/reviews/customers/' + customer.customerID)

      });



  }

  updateTotals(): void {
  
    this.params.api.forEachNode(function (rowNode) {
      let data = rowNode.data;
  
    });
 
  }

  refresh() {
    console.log("refresh")
  };

}
