import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { 
  Router, 
  NavigationEnd, 
  RouteConfigLoadStart, 
  RouteConfigLoadEnd, 
  ResolveStart, 
  ResolveEnd 
} from '@angular/router';
import { Subscription } from "rxjs";
import { MatSidenav } from '@angular/material/sidenav';
//import { TranslateService } from 'ng2-translate/ng2-translate';
import { filter } from 'rxjs/operators';


//import { TranslateService} from '@ngx-translate/core';
import { ThemeService } from '../../../../services/theme/theme.service';
import { AuthService } from 'app/services/auth/auth.service';
 // import * as domHelper from '../../../../helpers/dom.helper';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.template.html'
})
export class AdminLayoutComponent implements OnInit, OnDestroy {
  private isMobile;
  screenSizeWatcher: Subscription;
  isSidenavOpen: Boolean = false;
  isModuleLoading: Boolean = false;
  moduleLoaderSub: Subscription;
  url;
  logoUrl;
  @ViewChild(MatSidenav) private sideNave: MatSidenav;

  public User:any;
 

  constructor(
    private router: Router,
  //  public translate: TranslateService,
    public themeService: ThemeService,
    private auth: AuthService
  ) {
     
    // Close sidenav after route change in mobile
    // router.events.filter(event => event instanceof NavigationEnd).subscribe((routeChange: NavigationEnd) => {
    //   this.url = routeChange.url;
    //   if(this.isNavOver()) {
    //     this.sideNave.close()
    //   }
    //   //this.sideNave.close()
    // });
    

    this.logoUrl = this.auth.logoUrl.getValue();


    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((routeChange:NavigationEnd) => {
      this.url = routeChange.url;
      if(this.isNavOver()) {
        if(this.sideNave)
          this.sideNave.close()
      }
      if(this.url==='/reviews/jobs'){
        setTimeout(() => {
          this.sideNave.close()
        }, 200);
        
    //    console.log("this.url="+this.url+" closing  sideNave")
      }else{
     //   console.log("this.url="+this.url)
      }
    });





    // // Translator init
    // const browserLang: string = translate.getBrowserLang();
    // translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');
  }
  ngOnInit() {
    // Initialize Perfect scrollbar for sidenav
    let navigationHold = document.getElementById('scroll-area');
    // Ps.initialize(navigationHold, {
    //   suppressScrollX: true
    // });
    console.log(this.themeService.activatedThemeName);
    let objUser = localStorage.getItem("User");
    this.User = JSON.parse(objUser);
    
    this.moduleLoaderSub = this.router.events.subscribe(event => {
      if(event instanceof RouteConfigLoadStart || event instanceof ResolveStart) {
        this.isModuleLoading = true;
        // console.log('start')
       
      }
      if(event instanceof RouteConfigLoadEnd || event instanceof ResolveEnd) {
        this.isModuleLoading = false;
        // console.log('end')
        
      }
    });
    
  }
  ngOnDestroy() {
    this.moduleLoaderSub.unsubscribe();
  }
  isNavOver() {
    return window.matchMedia(`(max-width: 960px)`).matches;
  }
}