import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import * as domHelper from '../../../helpers/dom.helper';
import { ThemeService } from '../../../services/theme/theme.service';
import { AuthService } from '../../../services/auth/auth.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { FileUploadDropDialogComponent } from '../file-upload-drop-dialog/file-upload-drop-dialog.component';
import { SiteReviewService } from 'app/services/site-review.service';
import { environment } from 'environments/environment';
import { ResetComponent } from '../reset/reset.component';
import { IdbService } from 'app/services/idb.service';
import { ChatterService } from 'app/chat/shared/services/chatter.service';
import { JobMasterComponent } from 'app/views/site-review/job-master/job-master.component';
import { DataStoreService } from 'app/services/datastore.service';
import { BulkLoaderService } from 'app/services/bulk-loader.service';

export const isProduction = environment.production;// "https://sitereviewapi.azurewebsites.net";


@Component({
  selector: 'topbar',
  templateUrl: './topbar.template.html',
  styleUrls: ['./topbar.component.css'],
  //providers:[JobMasterComponent, MapService]
})
export class TopbarComponent implements OnInit {
  @Input() sidenav;
  @Input() notificPanel;
  @Output() onLangChange = new EventEmitter<any>();
  showChatter = false;
  chatterBoxClass = 'chatterbox-hide';
  currentLang = 'en';
  availableLangs = [{
    name: 'English',
    code: 'en',
  }, {
    name: 'Spanish',
    code: 'es',
  }];
  egretThemes;
  User;

  showWarning = false;

  constructor(
    private themeService: ThemeService,
    private auth: AuthService,
    private router: Router,
    private dialog: MatDialog,
    private idb: IdbService,
    private bulkLoader: BulkLoaderService,
    private db: SiteReviewService,
    private dataStore: DataStoreService

  ) { }

  ngOnInit() {
    this.showWarning = isProduction ? false : true;
    this.egretThemes = this.themeService.egretThemes;
    let objUser = localStorage.getItem("User");
    this.User = JSON.parse(objUser);
    this.themeService.changeTheme({ name: "egret-indigo", baseColor: "#3f51b5", isActive: true });
    console.log(this.egretThemes)
    console.log('environment.production', isProduction)
  }

  changeTheme2() {

  }

  // refreshData(){
  //   this.jM.refreshDataStore();
  // }

  clearIndexDB() {
    console.log('clearing local db')
    const dialogRef = this.dialog.open(ResetComponent, {
      height: '450px',
      width: '800px',
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log("the reset data dialog was closed")
      this.dialog.closeAll();
      //location.reload()
      this.auth.logout()
      //   this.router.navigate(['../../'], { relativeTo: this.route });
    });
  }




  toggleChatterBox() {
    if (this.chatterBoxClass == 'chatterbox-show') {
      this.chatterBoxClass = 'chatterbox-hide';
      this.showChatter = false;
    } else {
      this.chatterBoxClass = 'chatterbox-show'
      this.showChatter = true;
    }
  }

  setLang() {
    this.onLangChange.emit(this.currentLang);
  }

  changeTheme(theme) {

    this.themeService.changeTheme(theme);

  }

  toggleNotific() {
    this.notificPanel.toggle();
  }

  toggleSidenav() {
    this.sidenav.toggle();
  }

  toggleCollapse() {
    let appBody = document.body;
    domHelper.toggleClass(appBody, 'collapsed-menu');
    domHelper.removeClass(document.getElementsByClassName('has-submenu'), 'open');
  }

  async logout() {
    console.log('logging out...')
    try {
      await this.idb.clearData('jobs');
      this.auth.logout();
      console.log('Logged Out Successfully')
    } catch (e) {
      console.warn('ERROR LOGGING OUT', e)
      this.router.navigate(['/sessions/signin'])
    }
    //
  }

  async help() {
    var url = 'https://pinnaclepowerservices.zohodesk.com/portal/en/newticket';
    document.location.href=url;
    
   
  }

  async refreshJobList() {
    this.bulkLoader.jobListTrigger.next(new Date().getTime());

    // try {
    //   await this.idb.clearData('jobs').then(() => {
    //     console.log("job data cleared");
    //     location.reload();
    //   })
    // } catch (e) {
    //   console.warn('ERROR LOGGING OUT', e)
    //   this.router.navigate(['/sessions/signin'])
    // }
  }

  fileUploadDropZoneDlg() {
    const dialogRef = this.dialog.open(FileUploadDropDialogComponent, { width: '90%', height: '90%', panelClass: 'my-dialog' });
    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      } else {

      }
    });
  }
}
